import { useEffect } from "react";

const BrevoTracker = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.brevo.com/js/sdk-loader.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("Brevo script chargé.");
      window.Brevo = window.Brevo || [];
      window.Brevo.push([
        "init",
        {
          client_key: "cp7nshxqvv11c6aqkcpttvgc", // Remplace par ta clé client Brevo
        },
      ]);
      console.log("Brevo initialisé :", window.Brevo);
    };

    script.onerror = () => {
      console.error("Le script Brevo n'a pas pu être chargé.");
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default BrevoTracker;
