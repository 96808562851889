import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Modal from 'fast-modal-library';
import HidePasswordIcon from '../components/HidePasswordIcon';

/**
 * @file
 * @module Pages/Signup
 * @description Signup est une page qui permet à l'utilisateur de créer un nouveau compte.
 * - Elle contient un formulaire où l'utilisateur peut entrer son nom d'utilisateur, son mot de passe, son prénom, son nom de famille, 
 * le nom de son entreprise et son numéro SIRET.
 * - Lorsque le formulaire est soumis, une requête POST est envoyée à l'API pour créer le nouveau compte.
 * - Si la création du compte réussit, l'utilisateur est redirigé vers la page de connexion.
 * - Si la création du compte échoue, un message d'erreur est affiché.
 * - Les informations de l'entreprise et du SIRET peuvent être pré-remplies à partir de l'URL si elles sont fournies sous forme cryptée.
 * - Cette page utilise la bibliothèque CryptoJS pour le chiffrement AES.
 * - Cette page utilise le hook useState de React pour gérer l'état des champs de saisie et de la modale.
 * - Cette page utilise le hook useEffect de React pour décrypter les informations de l'entreprise et du SIRET à partir de l'URL.
 * - Cette page utilise le hook useParams de 'react-router-dom' pour obtenir les informations de l'entreprise et du SIRET à partir de l'URL.
 * - Cette page utilise le composant Modal pour afficher un message de confirmation après la création du compte.
 *
 */

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const Signup = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [siret, setSiret] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const regexEmail = /^[^@\s]{2,30}@[^\s@]{2,30}\.[^@\s]{2,}$/;
    const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-+]).{8,}$/;

    const { encryptedEntreprise, encryptedSiret } = useParams();

    useEffect(() => {
        if (encryptedEntreprise && encryptedSiret) {
            try {
                const urlSafeEntreprise = decodeURIComponent(encryptedEntreprise);
                const urlSafeSiret = decodeURIComponent(encryptedSiret);

                const bytesEntreprise = CryptoJS.AES.decrypt(urlSafeEntreprise, 'secret key 123');
                const originalEntreprise = bytesEntreprise.toString(CryptoJS.enc.Utf8);
                setCompany(originalEntreprise);
                //console.log('Entreprise décryptée:', originalEntreprise);

                const bytesSiret = CryptoJS.AES.decrypt(urlSafeSiret, 'secret key 123');
                const originalSiret = bytesSiret.toString(CryptoJS.enc.Utf8);
                setSiret(originalSiret);
                //console.log('SIRET décrypté:', originalSiret);
            } catch (error) {
                console.error('Erreur de décryptage:', error);
            }
        }
    }, [encryptedEntreprise, encryptedSiret]);

    const hide = () => {
        setShowModal(false);
        navigate('/signin');
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        if (!regexEmail.test(e.target.value)) {
            setUsernameError("Le nom d'utilisateur doit être une adresse e-mail valide.");
        } else {
            setUsernameError('');
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (!regexPassword.test(e.target.value)) {
            setPasswordError("Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.");
        } else {
            setPasswordError('');
        }
        if (confirmPassword && e.target.value !== confirmPassword) {
            setConfirmPasswordError("Les mots de passe ne correspondent pas.");
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password && e.target.value !== password) {
            setConfirmPasswordError("Les mots de passe ne correspondent pas.");
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!regexEmail.test(username)) {
            setError("Le nom d'utilisateur doit être une adresse e-mail valide.");
            return;
        }
        if (!regexPassword.test(password)) {
            setError("Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas.");
            return;
        }

        const formData = {
            username: username,
            password: password,
            firstname: firstname,
            lastname: lastname,
            company: company,
            siret: siret
        };
        // console.log(formData);

        try {
            const response = await fetch(apiPrefix+'register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Inscription réussie !');
                setShowModal(true); 
            } else if (response.status === 502) {
                setError("Un compte existe déjà avec cette adresse e-mail.");
            }
             else {
                console.error('Erreur lors de l\'inscription:', response.statusText);
            }
        } catch (error) {
            console.error('Erreur lors de la tentative d\'inscription:', error);
        }
    };

    return (
        <div className='signup-container'>
            <h2>Inscription</h2>
            <form onSubmit={handleSubmit}>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <label htmlFor="username">Nom d'utilisateur :</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                    />
                    {usernameError && <div className="error-message">{usernameError}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="password">Mot de passe :</label>
                        <div className='password-input'>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <HidePasswordIcon showPassword={showPassword}  setShowPassword={setShowPassword} />
                        </div>
                    {passwordError && <div className="error-message">{passwordError}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirmer le mot de passe :</label>
                        <div className='password-input'>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                        </div>
                    {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="firstname">Prénom :</label>
                    <input
                        type="text"
                        id="firstname"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastname">Nom de famille :</label>
                    <input
                        type="text"
                        id="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        disabled
                        hidden
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="siret"
                        value={siret}
                        onChange={(e) => setSiret(e.target.value)}
                        disabled
                        hidden
                    />
                </div>
                <button type="submit">S'inscrire</button>
            </form>
            {showModal && (
                <Modal contentModal="Inscription réussie !" hide={hide} />
            )}
        </div>
    );
};

export default Signup;